main {
    min-height: 80vh;
  }
  
  .rating span {
    margin: 0.1rem;
  }
  
  .rating svg {
    color: #f39c12;
  }
  
  .rating-text {
    font-size: 0.8rem;
    font-weight: 600;
    padding-left: 0.5rem;
  }
  
  .product-title {
    height: 2.5em; /* Set a fixed height */
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Add ellipsis for long text */
    white-space: nowrap; /* Prevent wrapping */
  }
  
  table td,
  table th {
    text-align: center;
  }
  
  .review {
    margin-top: 30px;
  }
  
  .review h2 {
    font-size: 24px;
    background: #f4f4f4;
    padding: 10px;
    border: 1px solid #ddd;
  }
  
  .review button {
    margin-top: 10px;
  }
  
  .carousel-caption {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  
  
  .navbar-bg-color {
    background: linear-gradient(45deg, rgb(233, 108, 108), blue);
  }
  
  .domain-font {
    font-family: 'Exo 2', sans-serif;
    /* font-family: 'Montserrat Alternates', sans-serif; */
    /* font-family: 'Pacifico', cursive; */
    /* font-family: 'Young Serif', serif; */
  }
  
  .sticky-header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff; /* Add your desired background color */
    z-index: 500; /* Adjust the z-index to control the stacking order */
    /* Add any other styles you want for the sticky header */
  }
  .carousel-item {
    /* width: 300px; Set your desired width */
    height: 400px; /* Set your desired height */
    overflow: hidden; /* Ensure the image doesn't overflow the fixed dimensions */
  }
  
  .carousel-item img {
    object-fit: fill; /* Ensure the image covers the fixed dimensions */
    width: 100%;
    height: 100%;
  }
  
  @media only screen and (max-width: 768px) { 
    .carousel-item {
      /* width: 300px; Set your desired width */
      height: 250px; /* Set your desired height */
    }
  }
  
  
  .card-background-gradient {
    background-color: snow;
  /* background-image: linear-gradient(62deg, #f0f0f0 0%, #E0C3FC 100%); */
  
  }
  
  .card-background-gradient:hover {
    background-color: #ffacdba8;
    /* background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%); */
  }
  
  .carousel-background {
    background-color: #0c0607;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  border-radius: 50px;
  overflow: hidden;
    
  }
  
  .user-carousel-background {
    background-color: #0c0607;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
    border-radius: 4%;
    overflow: hidden;
  }